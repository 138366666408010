<template>
    <section>
        <b-card>
            <validation-observer ref="userProfile">
                <b-row>
                    <b-col md="12">
                        <h3>Dati Personali</h3>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Nome">
                            <validation-provider #default="{ errors }" name="Nome" rules="required">
                                <b-form-input v-model="user.user_name" :state="errors.length > 0 ? false : null">
                                </b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Cognome">
                            <validation-provider #default="{ errors }" name="Cognome" rules="required">
                                <b-form-input v-model="user.user_surname"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Email">
                            <validation-provider #default="{ errors }" name="Email" rules="required">
                                <b-form-input v-model="user.user_email" disabled></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Ragione Sociale">
                            <validation-provider #default="{ errors }" name="Ragione Sociale" rules="required">
                                <b-form-input v-model="user.company_name"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Partita IVA">
                            <validation-provider #default="{ errors }" name="Partita IVA" rules="required">
                                <b-form-input v-model="user.user_vatnumber"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <p class="mr-2">Logo</p>
                        <validation-provider #default="{ errors }" name="account_logo">
                            <b-form-file ref="attachment_account_logo" class="d-none" v-model="account_logo"
                                @input="uploadLogo(account_logo)">
                            </b-form-file>
                            <b-button v-if="user.account_logo == ''" variant="outline-primary" pill
                                @click="$refs.attachment_account_logo.$el.childNodes[0].click()">
                                Carica il file</b-button>

                            <div v-if="user.account_logo == '' && uploadLogoLoading">
                                <b-spinner variant="primary" class="ml-1" />
                            </div>

                            <div v-if="user.account_logo">
                                <img height="100" :src="host + user.account_logo">
                            </div>

                            <b-button class="mt-2" :class="user.account_logo != '' ? 'd-block' : 'd-none'"
                                variant="outline-primary" pill
                                @click="$refs.attachment_account_logo.$el.childNodes[0].click()">
                                Sostituisci il file</b-button>

                            <div>
                                <small v-if="errors.length > 0" class="text-danger">Il campo è
                                    richiesto</small>
                            </div>
                        </validation-provider>

                    </b-col>
                </b-row>

                <hr />

                <b-row>
                    <b-col md="12">
                        <h3>Indirizzo (Sede Legale)</h3>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Indirizzo (sede legale)">
                            <validation-provider #default="{ errors }" name="Indirizzo (Sede Legale)" rules="required">
                                <b-form-input v-model="user.user_address_headquarter"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="CAP (sede legale)">
                            <validation-provider #default="{ errors }" name="CAP (Sede Legale)" rules="required">
                                <b-form-input v-model="user.user_zipcode_headquarter"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Città (sede legale)">
                            <validation-provider #default="{ errors }" name="Città (Sede Legale)" rules="required">
                                <b-form-input v-model="user.user_city_headquarter"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Provincia (sede legale)">
                            <validation-provider #default="{ errors }" name="Provincia (Sede Legale)" rules="required">
                                <b-form-input v-model="user.user_province_headquarter"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <hr />

                <b-row>
                    <b-col md="12">
                        <h3>Indirizzo (Sede Operativa)</h3>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Indirizzo (sede operativa)">
                            <validation-provider #default="{ errors }" name="Indirizzo (Sede Operativa)" rules="required">
                                <b-form-input v-model="user.user_address_operationoffice"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="CAP (sede operativa)">
                            <validation-provider #default="{ errors }" name="CAP (Sede Operativa)" rules="required">
                                <b-form-input v-model="user.user_zipcode_operationoffice"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Città (sede operativa)">
                            <validation-provider #default="{ errors }" name="Città (Sede Operativa)" rules="required">
                                <b-form-input v-model="user.user_city_operationoffice"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Provincia (sede operativa)">
                            <validation-provider #default="{ errors }" name="Provincia (Sede Operativa)" rules="required">
                                <b-form-input v-model="user.user_province_operationoffice"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <hr />

                <b-row>
                    <b-col md="12">
                        <h3>Dati Fatturazione</h3>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="SDI">
                            <validation-provider #default="{ errors }" name="SDI" rules="required">
                                <b-form-input v-model="user.user_sdi"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="PEC">
                            <validation-provider #default="{ errors }" name="PEC" rules="required">
                                <b-form-input v-model="user.user_pec"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Codice Ateco">
                            <validation-provider #default="{ errors }" name="Codice Ateco" rules="required">
                                <b-form-input v-model="user.user_ateco"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

            </validation-observer>

            <hr />

            <div class="text-right">
                <b-button variant="primary" @click="validationUserProfile">Modifica</b-button>
            </div>

            <hr />

            <b-row>
                <b-col md="12">
                    <h3>Modifica Password</h3>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Nuova password">
                        <validation-provider #default="{ errors }" name="password" rules="">
                            <b-form-input v-model="password"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group label="Conferma password">
                        <validation-provider #default="{ errors }" name="confirm_password" rules="">
                            <b-form-input v-model="confirm_password"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

            </b-row>
            <hr />

            <div class="text-right">
                <b-button variant="primary" @click="changePassword">Cambia password</b-button>
            </div>
        </b-card>

        <!-- Modal -->

        <b-modal title="Conferma Modifiche" id="modal-confirm" ok-title="Conferma" cancel-title="Annulla"
            @ok="editUserProfile">
            <p>
                Vuoi aggiornare le informazioni del profilo?
            </p>
        </b-modal>

    </section>
</template>

<script>
import { BAvatar, BButton, BCard, BFormGroup, BFormInput, BRow, BCol, BModal, BFormFile } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate';
import { required, email } from '@validations';
import { Requests } from '@/api/requests.js'
import { STORAGE_URL, BACKEND_BASE_URL } from '@/config';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BAvatar,
        BButton,
        BCard,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BModal,
        BFormFile
    },

    data: () => ({
        STORAGE_URL: STORAGE_URL,
        host: BACKEND_BASE_URL + '/',
        user: {},
        password: '',
        confirm_password: '',
        account_logo: null,
        uploadLogoLoading: false,
        //Validation Rules

        email,
        required
    }),

    created() {
        this.getUserProfile();
        localize('it');
    },

    methods: {
        async getUserProfile() {
            try {
                const response = await Requests.getUserProfile();
                this.user = response[0].profile.payload;
            } catch (err) {
                console.log(err)
            }
        },

        async uploadLogo(data) {

            this.uploadLogoLoading = true;

            let formData = new FormData()

            formData.append('Filedata', data)

            try {
                const response = await Requests.uploadImage(formData);
                this.user.account_logo = response.data;

            } catch (err) {
                console.log(err)
            }

            this.uploadLogoLoading = false;
        },

        async editUserProfile() {

            let data = new FormData();

            data.append('user_name', this.user.user_name);
            data.append('user_surname', this.user.user_surname);
            data.append('user_companyname', this.user.company_name);
            data.append('user_vatnumber', this.user.user_vatnumber);
            data.append('user_address_headquarter', this.user.user_address_headquarter);
            data.append('user_zipcode_headquarter', this.user.user_zipcode_headquarter);
            data.append('user_city_headquarter', this.user.user_city_headquarter);
            data.append('user_province_headquarter', this.user.user_province_headquarter);
            data.append('user_address_operationoffice', this.user.user_address_operationoffice);
            data.append('user_zipcode_operationoffice', this.user.user_zipcode_operationoffice);
            data.append('user_city_operationoffice', this.user.user_city_operationoffice);
            data.append('user_province_operationoffice', this.user.user_province_operationoffice);
            data.append('account_logo', this.user.account_logo);
            data.append('user_sdi', this.user.user_sdi);
            data.append('user_pec', this.user.user_pec);
            data.append('user_ateco', this.user.user_ateco);

            try {
                await Requests.editUserProfile(data);
                this.$swal.fire({
                    title: 'Profilo Aggiornato',
                    icon: 'success',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                    didClose: async () => { this.$router.go() }
                })
            } catch (err) {
                console.log(err)
            }
        },

        validationUserProfile() {
            return new Promise((resolve, reject) => {
                this.$refs.userProfile.validate().then((success) => {
                    if (success) {
                        this.$bvModal.show('modal-confirm');
                        resolve(true);
                    } else {
                        reject();
                    }
                });
            });
        },


        async changePassword() {
            if (!this.password || this.password !== this.confirm_password) {
                this.$swal('Attenzione', 'La password non può essere vuota e deve coincidere con la conferma', 'error');
                return;
            }
            try {
                const response = await Requests.changePassword({ password: this.password });
                debugger;
                if (response.change_password.success == 'false') {
                    this.$swal({
                        title: 'Operazione Fallita',
                        text: response[0].change_password.feedback,
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                } else {
                    await this.$swal({
                        title: 'Modifiche Effettuate',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                }
            } catch (e) {

            }

        }
    }
}

</script>
