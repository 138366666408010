var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('validation-observer',{ref:"userProfile"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Dati Personali")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.user.user_name),callback:function ($$v) {_vm.$set(_vm.user, "user_name", $$v)},expression:"user.user_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Cognome"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_surname),callback:function ($$v) {_vm.$set(_vm.user, "user_surname", $$v)},expression:"user.user_surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.user_email),callback:function ($$v) {_vm.$set(_vm.user, "user_email", $$v)},expression:"user.user_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Ragione Sociale"}},[_c('validation-provider',{attrs:{"name":"Ragione Sociale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.company_name),callback:function ($$v) {_vm.$set(_vm.user, "company_name", $$v)},expression:"user.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Partita IVA"}},[_c('validation-provider',{attrs:{"name":"Partita IVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_vatnumber),callback:function ($$v) {_vm.$set(_vm.user, "user_vatnumber", $$v)},expression:"user.user_vatnumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('p',{staticClass:"mr-2"},[_vm._v("Logo")]),_c('validation-provider',{attrs:{"name":"account_logo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"attachment_account_logo",staticClass:"d-none",on:{"input":function($event){return _vm.uploadLogo(_vm.account_logo)}},model:{value:(_vm.account_logo),callback:function ($$v) {_vm.account_logo=$$v},expression:"account_logo"}}),(_vm.user.account_logo == '')?_c('b-button',{attrs:{"variant":"outline-primary","pill":""},on:{"click":function($event){return _vm.$refs.attachment_account_logo.$el.childNodes[0].click()}}},[_vm._v(" Carica il file")]):_vm._e(),(_vm.user.account_logo == '' && _vm.uploadLogoLoading)?_c('div',[_c('b-spinner',{staticClass:"ml-1",attrs:{"variant":"primary"}})],1):_vm._e(),(_vm.user.account_logo)?_c('div',[_c('img',{attrs:{"height":"100","src":_vm.host + _vm.user.account_logo}})]):_vm._e(),_c('b-button',{staticClass:"mt-2",class:_vm.user.account_logo != '' ? 'd-block' : 'd-none',attrs:{"variant":"outline-primary","pill":""},on:{"click":function($event){return _vm.$refs.attachment_account_logo.$el.childNodes[0].click()}}},[_vm._v(" Sostituisci il file")]),_c('div',[(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v("Il campo è richiesto")]):_vm._e()])]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Indirizzo (Sede Legale)")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Indirizzo (sede legale)"}},[_c('validation-provider',{attrs:{"name":"Indirizzo (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_address_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_address_headquarter", $$v)},expression:"user.user_address_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CAP (sede legale)"}},[_c('validation-provider',{attrs:{"name":"CAP (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_zipcode_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_zipcode_headquarter", $$v)},expression:"user.user_zipcode_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Città (sede legale)"}},[_c('validation-provider',{attrs:{"name":"Città (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_city_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_city_headquarter", $$v)},expression:"user.user_city_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Provincia (sede legale)"}},[_c('validation-provider',{attrs:{"name":"Provincia (Sede Legale)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_province_headquarter),callback:function ($$v) {_vm.$set(_vm.user, "user_province_headquarter", $$v)},expression:"user.user_province_headquarter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Indirizzo (Sede Operativa)")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Indirizzo (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"Indirizzo (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_address_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_address_operationoffice", $$v)},expression:"user.user_address_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CAP (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"CAP (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_zipcode_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_zipcode_operationoffice", $$v)},expression:"user.user_zipcode_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Città (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"Città (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_city_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_city_operationoffice", $$v)},expression:"user.user_city_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Provincia (sede operativa)"}},[_c('validation-provider',{attrs:{"name":"Provincia (Sede Operativa)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_province_operationoffice),callback:function ($$v) {_vm.$set(_vm.user, "user_province_operationoffice", $$v)},expression:"user.user_province_operationoffice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Dati Fatturazione")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"SDI"}},[_c('validation-provider',{attrs:{"name":"SDI","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_sdi),callback:function ($$v) {_vm.$set(_vm.user, "user_sdi", $$v)},expression:"user.user_sdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"PEC"}},[_c('validation-provider',{attrs:{"name":"PEC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_pec),callback:function ($$v) {_vm.$set(_vm.user, "user_pec", $$v)},expression:"user.user_pec"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Codice Ateco"}},[_c('validation-provider',{attrs:{"name":"Codice Ateco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.user.user_ateco),callback:function ($$v) {_vm.$set(_vm.user, "user_ateco", $$v)},expression:"user.user_ateco"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationUserProfile}},[_vm._v("Modifica")])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Modifica Password")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nuova password"}},[_c('validation-provider',{attrs:{"name":"password","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Conferma password"}},[_c('validation-provider',{attrs:{"name":"confirm_password","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.changePassword}},[_vm._v("Cambia password")])],1)],1),_c('b-modal',{attrs:{"title":"Conferma Modifiche","id":"modal-confirm","ok-title":"Conferma","cancel-title":"Annulla"},on:{"ok":_vm.editUserProfile}},[_c('p',[_vm._v(" Vuoi aggiornare le informazioni del profilo? ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }